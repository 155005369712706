<template>
  <div style="position: relative;overflow: hidden;">
    <div style="margin-left: 10vw;">
      <topList></topList>
    </div>

    <router-view> </router-view>

    <div style="margin-left: 12.5vw;">
      <homeBottom></homeBottom>
    </div>

    <div>
      <phoneBar></phoneBar>
    </div>
   
  </div>
</template>

<script>
import homeBottom from '@/components/homeBottom.vue'
import topList from '@/components/topList.vue'

import phoneBar from './components/phonebar.vue'
export default {
  components: {
    topList,
    homeBottom,
    phoneBar
  },
  data() {
    return {
      show3: false,
    }
  },
  mounted() {
  }
  ,
  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
