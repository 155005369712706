<template>
    <div>
        <div class="fath">
            <el-carousel height="44vh" :interval="3000" motion-blur autoplay>
                <el-carousel-item v-for="item in picture" :key="item.img" style="height: 44vh;width: 75vw;">
                    <img :src="item.img" alt="" style="width: 100%; height: 100%;object-fit: cover;">
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
export default {
    name: 'carouselBar',
    data() {
        return {
            picture: []
        }
    },
    created() {
        this.getImgList()
    },
    methods: {
        getImgList() {
          
            this.$http.get(`/door/getImgList`,{params:{imgCat:0}}).then(res => {
                for (let i=0;i<res.data.rows.length;i++){
                    const img=`${this.$baseUrl}${res.data.rows[i].imgUrl}`
                    this.picture.push({img:img});
                }
            })
        }
    }
}
</script>

<style lang="scss">
.fath{
    margin-top: 0.5vh;width: 75vw;
    margin-left: 2.5vw;
}
</style>