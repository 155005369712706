<template>
  <div>
    <div class="father">
      <!-- 轮播 -->
      <topBar></topBar>
      <!--  -->
      <div class="jieshao_1">


        <div style="" class="jieshao_2">
          <el-timeline style="max-width: 600px">
            <el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon"
              :type="activity.type" :color="activity.color" :size="activity.size" :hollow="activity.hollow"
              @click="hanerto(activity.content)" style="cursor: pointer; ">
              <span style="font-size: 0.8vw;">{{ activity.content }}</span>
            </el-timeline-item>
          </el-timeline>
        </div>
        <!-- 右边 -->
        <div class="jieshao_3">
          <div class="right_logo">
            <img src="../../assets/img/gsjs.png ">
          </div>

          <div style="" class="jieshao_4">
           <div style="" class="jieshao_5">
       
               37000cm威尼斯（简称：37000cm威尼斯），成立于2011年3月，是一家融合现代信息科技和创意设计的文化科技型企业。公司拥有专业技术深厚的研发、实施和运营团队，以市场需求为引导，为客户提供高效、有价值的产品、技术服务和解决方案是我们的目标。

2023年是公司再创业之年，以团队建设、技术转型、业务整合为契合点，重塑企业发展之旅。  公司依托AI、（大）数据（信息）分析等新型信息技术和文化创意设计，专注于智慧文旅和教育、行业智能监管服务平台、服务于企业运营的全流程优化需求的数字化建设等业务。
            </div> 
            <div class="jieshao_6" style="">
              <img :src="textimg" alt="">
            </div>
          </div>

           <div class="jieshao_7" style="">
           
          </div> 
        </div>
      </div>


    </div>

  </div>
</template>

<script>
import topBar from '@/components/topBar.vue'
export default {
  name: 'jieShao',
  components: {
    topBar,
  },
  data() {
    return {
      textimg:"",
      text:"",
      name: "",//公司名称
      activities: [

        {
          content: '',
          color: '#fff',
          type: 'primary',
          hollow: true,
        },
        {
          content: '',
          color: '#1c60c3',
        },
        {
          content: '',
          color: '#1c60c3',

        },
        {
          content: '',
          color: '#1c60c3',
        },
        {
          content: '',
          color: '#1c60c3',
        },
        {
          content: '',
          color: '#1c60c3',
        },
      ]
    }
  },
  created() {
    this.getlist()
    this.gettext()
  },
  methods: {
    hanerto(e) {


      // if(e=='公司介绍'){
      //   this.$router.push({ name: 'gsjieShao' })
      // }
      if (e == '公司业务') {
        this.$router.push({ name: 'gsyeWu' })
      }
      if (e == '公司团队') {
        this.$router.push({ name: 'gstuanDui' })
      }
      if (e == '公司资质') {
        this.$router.push({ name: 'gsziZhi' })
      }
      if (e == '企业文化') {
        this.$router.push({ name: 'gswenHua' })
      }
      if (e == '技术方向') {
        this.$router.push({ name: 'gsjiShu' })
      }
    },
    //获取左边的时间线
    getlist() {
      this.$http.get('/door/getCategory/1').then(res => {
        this.id= res.data.data[0].id
    
        this.activities.forEach((activity, index) => {
          // 查找res.data.data中对应索引的元素
          const title = res.data.data[index].title;
          // 替换content字段
          activity.content = title;
        });
      this.gettext()
      })
    },
    //右边的文章
    gettext(){
      this.$http.get('/door/getArticleList', { params: { articleCat:this.id} }).then(res => {
            
                  this.text=res.data.rows[0].content
                  this.textimg=`${this.$baseUrl}${res.data.rows[0].contentImg}`
            })
    }
  }
} 
</script>

<style lang="scss" scoped>
.father {
  width: 80vw;
  margin-left: 10vw;

}

.jieshao_1 {

  width: 75vw;
  height: 90vh;
  margin-left: 2.5vw;
  background-color: #edf2f6;
  padding-top: 3vh;
  display: flex;
  margin-top: 0vh;
}

.jieshao_2 {
  width: 6vw;
  margin-left: 6vw;
  margin-top: 3vh;
}

.jieshao_3 {
  width: 68%;
  height: 80vh;
  background-color: #fff;
  margin-left: 0vw !important;
  padding: 2vw;

}

.jieshao_4 {
  width: 100%;
  margin-top: 3vh;
  display: flex
}

.jieshao_5 {
  width: 55%;
  font-size: 16px;
  color: #2d2d2d;
  text-indent: 1.3vw;
  line-height: 4vh;
  float: left;
  margin-top: 2vh;
}

.jieshao_6 {
  width: 40%;
  height: 28vh;
  margin-left: 1vw;
  img{
    width: 100%;
    height: 100%;
  }
}

.jieshao_7 {
  margin-top: 2vh;
  width: 90%;
  font-size: 16px;
  color: #2d2d2d;
  text-indent: 1.3vw;
  line-height: 4vh;
  float: left;
}

.right_logo {
  width: 8.3vw;
  height: 7vh;


  img {
    width: 100%;
    height: 100%;
  }
}
</style>