<template>
    <div>
      <div class="father">
        <!-- 轮播 -->
        <topBar></topBar>
        <!--  -->
        <div class="jieshao_1">
  
          <div style="" class="jieshao_2">
            <el-timeline style="max-width: 600px">
              <el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon"
                :type="activity.type" :color="activity.color" :size="activity.size" :hollow="activity.hollow"   @click="hanerto(activity.content)"  style="cursor: pointer;">
                <span style="font-size: 0.8vw;">{{ activity.content }}</span>
              </el-timeline-item>
            </el-timeline>
          </div>
          <!-- 右边 -->
          <div class="jieshao_3" style="">
            <div style="width: 8.3vw;height: 7vh;">
              <img src="../../assets/img/zizhi.png" alt="" style="width: 100%;height: 100%">
            </div>
          
            <div class="img_box">
     
              <div class="zizhi_img" v-for="(item,index) in imglist" :key="index">
                <img :src="item.img" alt="">
              </div>
            </div>
          </div>
        </div>
  
  
      </div>
  
    </div>
  </template>
  
  <script>
  import topBar from '@/components/topBar.vue'
  export default {
    name: 'gsziZhi',
    components: {
      topBar,
    },
    data() {
      return {
        imglist:[
          {img:"http://admin.jsxinyuansu.com/profile/upload/2024/08/01/20170808083219468_20240801173231A027.jpg"},
          {img:"http://admin.jsxinyuansu.com/profile/upload/2024/08/01/20170808083219613_20240801173241A029.jpg"},
          {img:"http://admin.jsxinyuansu.com/profile/upload/2024/08/01/20170808083219861_20240801173246A030.jpg"},
          {img:"http://admin.jsxinyuansu.com/profile/upload/2024/08/01/20170808083219181_20240801173225A026.jpg"},
          {img:"http://admin.jsxinyuansu.com/profile/upload/2024/08/01/20170808083219472_20240801173236A028.jpg"},
        ],
        text:'',
        activities: [
  
          {
            content: '',
            color: '#1c60c3',
          },
          {
            content: '',
            color: '#1c60c3',
          },
          {
            content: '',
            color: '#1c60c3',
  
          },
          {
            content: '',
            color: '#fff',
            type: 'primary',
            hollow: true,
          },
          {
            content: '',
            color: '#1c60c3',
          },
          {
          content: '',
          color: '#1c60c3',
        },
        ]
      }
    },
    created() {
    this.getlist()
  },
    methods: {
        hanerto(e){
   
   
      if(e=='公司介绍'){
        this.$router.push({ name: 'jieShao' })
      }
      if(e=='公司业务'){
        this.$router.push({ name: 'gsyeWu' })
      }
      if(e=='公司团队'){
        this.$router.push({ name: 'gstuanDui' })
      }
    //   if(e=='公司资质'){
    //     this.$router.push({ name: 'gsziZhi' })
    //   }
      if(e=='企业文化'){
        this.$router.push({ name: 'gswenHua' })
      }
      if (e == '技术方向') {
        this.$router.push({ name: 'gsjiShu' })
      }
    },
    getlist() {
      this.$http.get('/door/getCategory/1').then(res => {
        this.id= res.data.data[3].id
        this.activities.forEach((activity, index) => {
          // 查找res.data.data中对应索引的元素
          const title = res.data.data[index].title;
          // 替换content字段
          activity.content = title;
        });
        this.gettext()
      })
    },
   //右边的文章
   gettext(){
      this.$http.get('/door/getArticleList', { params: { articleCat:this.id} }).then(res => {
                  this.text=res.data.rows[0].content     
            })
    }
    }
  } 
  </script>
  
  <style lang="scss" scoped>
  .father {
    width: 80vw;
    margin-left: 10vw;
  }
  
  .jieshao_1 {
    width: 75vw;

  margin-left: 2.5vw;
  background-color: #edf2f6;
  padding-top: 3vh;
  display: flex;
  margin-top: 0vh;
  }
  
  .jieshao_2 {
    width: 6vw;
    margin-left: 6vw;
    margin-top: 3vh;
  }
  
  .jieshao_3 {
    width: 68%;

  background-color: #fff;
 margin-left: 0vw !important;
 padding: 2vw;
  }
  
  .jieshao_4 {
    width: 100%;
    margin-top: 3vh;
    display: flex
  }
  
  .jieshao_5 {
    width: 55%;
    font-size: 20px;
    color: #2d2d2d;
    text-indent: 26px;
    line-height: 42px;
    float: left;
  }
  
  .jieshao_6 {
    width: 50%;
    height: 280px;
    margin-left: 2vw;
    img{
    width: 100%;
    height: 100%;
  }
  }
  
  .jieshao_7 {
    margin-top: 2vh;
    width: 90%;
    font-size: 20px;
    color: #2d2d2d;
    text-indent: 26px;
    line-height: 42px;
    float: left;
  }
  .zizhi_img{
    width:25vw;
    height: 35vh;
    margin-bottom: 3vh;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .img_box{

    margin-left: 10vw;
    margin-top: 4vh;
    
  
  }
  </style>