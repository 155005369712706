<template>
  <div class="anli_1">
    <div style="" class="anli_2">
      <topBar></topBar>
    </div>

    <div class="text3">
      <div class="title">{{ title }}</div>
      <div class="time">
        <span class="timestyle">{{ time }}</span>
      </div>
      <div v-html="htmltext" class="ql-editor"></div>

      <div class="bottom">
        <span class="left" @click="handerto">下一篇： <span style="color: #4b4949; font-size: 14px;cursor: pointer;">{{ xiayipian }}</span> </span>
        <div class="btstyle" @click="handerback">返回列表</div>
      </div>

    </div>
  </div>
</template>

<script>

import topBar from '@/components/topBar.vue'
export default {
  name: 'dongtaiText',
  components: {
    topBar
  },
  data() {
    return {
      nextid:'',
      id: '',
      htmltext: '',
      title: '',
      xiayipian: ''
    }
  },
  created() {
    this.id = this.$route.query.id
 
    this.gettext()


    this.getnextlist()

  },
  methods: {
    //获取列表
    gettext() {
      this.$http.get(`/door/getArticle/${this.id}`,).then(res => {
   
        this.total = res.data.data.total
        this.title = res.data.data.title
        this.time = res.data.data.createTime
        this.htmltext = res.data.data.content
      })
    },
    //下一篇跳转
    handerto(){
      localStorage.setItem('text', '新闻动态')
      
      window.open(this.$router.resolve({ path: '', query: { id: this.nextid } }).href, '_blank');
      // window.open(this.$router.resolve({ path: 'dongtaiText', query: { id: this.nextid } }).href, '_blank');
    },
    //获取下一篇的字段
    getnextlist() {
      const text = {
        id: this.id,
        articleCat: 7
      }
      this.$http.get('/door/getNext/', { params: text }).then(res => {

        if (res.data.data) {
          this.xiayipian = res.data.data.title
          this.nextid= res.data.data.id
        } else {
          this.xiayipian = ''
        }

      });
    },
    handerback() {
      this.$router.push({ name: 'dongTai' })
    }

  }
}
</script>

<style lang="scss" scoped>
.anli_1 {
  padding-left: 10vw;

  .anli_2 {
    width: 80vw;
  }
}

.text3 {
  padding-top: 8vh;
  width: 75vw;
  margin-left: 2.5vw;
  background-color: #edf2f6;

}

.title {
  width: 100%;
  height: 5vh;


  text-align: center;

  line-height: 5vh;
  color: #4a4a4a;
  font-size: 20px;
}

.time {
  width: 100%;
  height: 5vh;
  line-height: 4vh;
  position: relative;

  .timestyle {
    font-size: 14px;
    color: #4a4a4a;
    position: absolute;
    right: 25vw;
  }
}

.bottom {
  padding-left: 10vw;
  width: 80%;
  height: 7vh;

}

.left {
  line-height: 40px;
  float: left;
  font-size: 18px;
}

.btstyle {
  cursor: pointer;
  float: right;
  width: 116px;
  height: 40px;
  background: #1f5299;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  color: #FFFFFF;
  border-radius: 4px;
  border: 0;
}

.h-html {
  text-indent: 2em;
  padding-left: 6vw;
  padding-right: 5vw;

}
</style>