<template>
    <div>
        <!-- 轮播 -->
        <div class="top">
            <topBar></topBar>
        </div>

        <!-- 中间主图 -->
        <div class="center">
            <!-- 主题的，每一行item -->
            <div class="center-item">
                <div class="center-left">
                    <div style="height: 28vh;">
                        <div class="item-title">总经理助理（营销方向） </div>
                        <div v-for="(item, index) in iteminfo" :key="index">
                            <div class="item-info">{{ item.info }}</div>
                        </div>
                     
                    </div>
                    <div class="item-bottom"></div>
                    <div class="item-email">HR邮箱：hr@xinyuansu.com</div>
                </div>

                <div class="center-right">
                    <div style="height: 28vh">
                        <div class="item-title">销售工程师</div>
                    <div v-for="(item, index) in iteminfo2" :key="index">
                        <div class="item-info">{{ item.info }}</div>
                    </div>
                    </div>
                   
                    <div class="item-bottom"></div>
                    <div class="item-email">HR邮箱：hr@xinyuansu.com</div>
                </div>
            </div>

            <!-- 占位 -->
            <div class="zhanwei"></div>
            <!--  -->
        </div>

    </div>

</template>

<script>
import topBar from '@/components/topBar.vue'

export default {
    name: 'zhaoPing',
    components: {
        topBar
    },
    data() {
        return {
            iteminfo: [
                { info: '1.全日制本科及以上学历，市场营销或计算机相关专业优先。' },
                { info: '2.五年及以上工作经验，不少于一年以上营销工作经验。' },
                { info: '3.优秀的人际关系处理能力，优秀的团队合作精神。' },
                { info: '4.学习能力强，执行能力强，擅于组织协调。' },
                { info: '5.具备独立开发市场能力，具备独立商务处理能力。' },
                { info: '6.诚实守信，形象气质佳。' },
                { info: '7.熟练驾驶技术。' },
            ],
            iteminfo2: [
                { info: '1.全日制本科及以上学历' },
                { info: ' 2.市场营销或计算机相关专业优先两年以上市场销售相关工作经验对信息化行业及市场有充分了解' },
                { info: '3.优秀的人际交往能力 具备优秀的人际关系处理能力，良好的团队合作精神。' },
                { info: '4.工作积极主动，学习能力强，抗压能力强。' },
                { info: '5.具备优秀的文案处理能力，熟练使用主流办公软件。' },
                { info: '6.有信息化软件销售经验，有系统方案处理能力者优先录用。' },

            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.top {
    width: 80vw;
    margin-left: 10vw;
}

.center {
    width: 75vw;
    padding-top: 5vh;


    background-color: #edf2f6;
    margin-left: 12.5vw;

}

.center-item {
    width: 70vw;
    margin-left: 8vw;
    margin-bottom: 2vh;

    display: flex;
}

.center-left {
    width: 29vw;

    background-color: #fff;
}

.center-right {
    width: 29vw;

    background-color: #fff;
    margin-left: 1vw;
}

.item-title {
    height: 3vh;
    font-size: 18px;
    margin-top: 3vh;
    padding-left: 1.5vw;
    color: #1F5299;
}

.item-info {
    font-size: 14px;
    color: #555555;
    line-height: 23px;
    margin-top: 10px;
    padding-left: 1.5vw;
    padding-right: 2vw;
}

.item-bottom {
    width: 26vw;
    height: 3vh;
    margin-left: 2vw;

    border-bottom: 1px dashed #CCCCCC;
}

.item-email {
    color: #555555;
    display: flex;
    align-items: center;
    margin-left: 2vw;
    width: 20vw;
    height: 5vh;
    font-size: 16px;

}

.zhanwei {
    width: 100%;
    height: 5vh;

}
</style>