<template>
  <div>
    <!-- 大卡片 -->
    <div style="" class="fangan_1">
      <img :src="topimg" style="width: 100%;height: 100%;" alt="">
    </div>

    <!--  -->
    <div style="" class="fangan_15">

      <div style="" class="fangan_2">
        <button class="left" @click="handernext" :disabled="lesttitle === ''">上一篇：{{ lesttitle ? lesttitle : '暂无'
          }}</button>
        <button class="left" @click="handerup" :disabled="nexttitle === ''">下一篇：{{ nexttitle ? nexttitle : '暂无'
          }}</button>
      </div>

      <div style="" class="fangan_9">{{ titlename }}</div>
      <!-- <div v-if="isCollapsed" class="button_style">
        <div  class="fangan_titlename">{{ titlename }}</div>
        <button @click="toggleCollapse" >展开查看详情</button>
      </div>
      <div v-else class="button_style">
        <div class="fangan_9">{{ titlename }}</div>
        <button @click="toggleCollapse" >收起</button>
      </div> -->

      <div class="card_1">

        <div v-for="(item, index) in chanpinglist" :key="index">

          <!-- //这里有一个判断，如果下表是单数就渲染左图右字，如果是双数就渲染右字左图 -->
          <!-- ！！！！！！！！！！！！！！ -->
          <div class="card_2 left_list wow animate__animated animate__bounceInLeft " v-if="index % 2 === 0">
            <div class="card_left">
              <el-image preview-teleported style="width: 100%; height: 100%" :src="item.contentImg" :zoom-rate="1.2"
                :max-scale="7" :min-scale="0.2" :preview-src-list="[item.contentImg]" :initial-index="999" fit="cover"
                hide-on-click-modal />
            </div>
            <div class="card_right">
              <p>{{ item.title }}</p>
              <span v-html="item.content"></span>
              <!-- <span>{{ item.content }}</span> -->
            </div>
          </div>
          <!-- ！！！！！！！！！！！！！！！！！！！ -->
          <div class="card_2 right_list wow animate__animated animate__bounceInRight" v-else>
            <div class="card_right">
              <p>{{ item.title }}</p>
              <span v-html="item.content"></span>
              <!-- <span>{{ item.content }}</span> -->
            </div>
            <div class="card_left">
              <el-image preview-teleported style="width: 100%; height: 100%" :src="item.contentImg" :zoom-rate="1.2"
                :max-scale="7" :min-scale="0.2" :preview-src-list="[item.contentImg]" :initial-index="999" fit="cover"
                hide-on-click-modal />
            </div>
          </div>
        </div>
        <!-- ！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！ -->
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'fangAn',
  data() {
    return {

      isCollapsed: true, // 是否折叠的标志，
      nextid: '',
      nexttitle: '',
      lestid: '',
      lesttitle: '',
      id: '',
      parentId: '',
      topimg: '',
      titlename: '',
      idnum: '',
      chanpinglist: [],
      banrlist: [
        { img: 'uploadfile/2017/0921/20170921053650831.png' },
        { img: 'uploadfile/2017/0921/20170921053820812.png' },
        { img: 'uploadfile/2017/0921/20170921054054277.png' },
        { img: 'uploadfile/2017/0921/20170921053850200.png' },
      ]
    }
  },
  mounted() {
    this.idnum = this.$route.query.id
   
    this.getcard()
    this.gettitle()
  },
  created() {
    // this.getcard()
    // this.gettitle()
    // this.getnexttitle()
  },
  methods: {


    //获取列表信息
    getcard() {
      this.idnum = this.$route.query.id

      const cat = { articleCat: this.idnum }
      this.$http.get('/door/getArticleList', { params: cat }).then(res => {

        this.chanpinglist = res.data.rows.map(item => {
          // 在这里对每个item的coverImg字段前面拼接'http'
          const img = `${this.$baseUrl}${item.contentImg}`;
          return { ...item, contentImg: img };
        });

      })
    },
    //获取顶部大图片和标题
    gettitle() {
      this.$http.get(`/door/getCategoryById/${this.idnum}`,).then(res => {

        this.id = res.data.data.id
        this.parentId = res.data.data.parentId
        this.titlename = res.data.data.remark
        this.topimg = `${this.$baseUrl}${res.data.data.contentImg}`
 
        this.getnexttitle()
        this.getlesttitle()
      })
    },
    //获取下一篇的字
    getnexttitle() {
  
      this.$http.get(`/door/getNextCat`, { params: { id: this.id, parentId: this.parentId } },).then(res => {
        if (res.data.data) {
          this.nexttitle = res.data.data.title
          this.nextid = res.data.data.id
        } else {
          this.nexttitle = ''
        }
      })
    },
    //获取上一篇的字
    getlesttitle() {
 
      this.$http.get(`/door/getUpCat`, { params: { id: this.id, parentId: this.parentId } },).then(res => {
        if (res.data.data) {
          this.lesttitle = res.data.data.title
          this.lestid = res.data.data.id
        } else {
          this.lesttitle = ''
        }
      })
    },
    //点击下一篇跳转
    handerup() {
    
      localStorage.setItem('text', '解决方案')
      window.open(this.$router.resolve({ path: 'fangAn', query: { id: this.nextid } }).href, '_blank');
    },
    handernext() {
      localStorage.setItem('text', '解决方案')
      window.open(this.$router.resolve({ path: 'fangAn', query: { id: this.lestid } }).href, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}
.button_style{
 
  button{
    border: 0;
    background-color: #fff;
    margin-left: 35vw;
  }
}

.fangan_1 {
  width: 75vw;
  margin-left: 12.5vw;
}

.fangan_2 {
  width: 95%;
  border: 0;
  display: flex;
  justify-content: space-between;

}

.demo-image__error .el-image {
  width: 100%;
  height: 100%;
}

.fangan_9 {
  text-indent: 2em;
  width: 60vw;
  margin-left: 7.5vw;
  line-height: 4.5vh;
  margin-top: 2vh;
  font-size: 1vw;
  color: #7a7a7a;
  margin-bottom: 3vh;
}
.fangan_titlename {
  text-indent: 2em;
  width: 60vw;
  margin-left: 7.5vw;
  height: 5vh;
  line-height: 5vh;
  margin-top: 2vh;
  font-size: 1vw;
  color: #7a7a7a;
  margin-bottom: 3vh;
  overflow: hidden;
}

.fangan_10 {
  font-size: 24px;
  text-align: center;
  color: #7a7a7a;
  margin-top: 1vh;
  display: block;
}

.fangan_11 {
  padding-left: 2.5vw;
  display: flex;
}

.fangan_12 {
  width: 14vw;
  height: 25vh;
  background-color: #205299;
  margin-top: 5vh;
}

.fangan_13 {
  margin-left: 9.8vw;
  width: 3vw;
  display: flex;
  justify-content: space-between;
  margin-top: 4vh;
}

.fangan_14 {

  width: 55vw;
  height: 32vh;
  margin-top: 5vh;
  margin-left: 4vw;
}

.fangan_15 {

  width: 75vw;
  padding-bottom: 5vh;
  background-color: #f8f8f8;
  margin-left: 12.5vw;
  padding-top: 4vh;

}



.left {
  border: 0;
  cursor: pointer;
  transition: transform 0.3s ease;
  background-color: #f8f8f8;
  margin-left: 5vw;
  font-size: 0.8vw;
  color: #4b4949;

}

.anli1 {
  border: 0;
  cursor: pointer;
  transition: transform 0.3s ease;
  width: 1vw;
  height: 3vh;
  background-size: cover;
  border: 0;
  background-repeat: no-repeat;
  background-image: url(statics/gongsi/images/zuo.png);


}

.anli2 {
  border: 0;
  cursor: pointer;
  transition: transform 0.3s ease;
  width: 1vw;
  height: 3vh;
  background-size: cover;
  border: 0;
  background-repeat: no-repeat;
  background-image: url(statics/gongsi/images/you.png);
}

.left_title {
  margin: 2vw 0 0 6vw;
  width: 7vw;
  height: 6vh;

  img {
    width: 100%;
    height: 100%;
  }
}

.card_1 {
  margin-top: 5vh;
  margin-left: 8.5vw;
}

.card_2 {
  width: 58vw;
  // height: 34vh;
  display: flex;
  margin-bottom: 8vh;
  background-color: #fff;
}

.card_left {
  width: 18vw;

  height: 38vh;
  // position: relative;
  text-align: center;
  /* 用于旧浏览器或行内元素的居中 */
  display: flex;
  /* 使用Flexbox */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中，如果需要的话 */

  /* 如果你想限制容器高度 */
  overflow: hidden;
  /* 如果图像高度超过容器，防止溢出 */

  img {
    height: 100%;
    width: 100%;
  }

  // .zoomed {
  //   transform: scale(2);
  //   /* 放大2倍 */
  //   transition: transform 0.2s;
  //   /* 平滑过渡效果 */
  // }
}

.card_title {
  // position: absolute;
  top: 0;
  z-index: 999;
}

.card_right {
  width: 40vw;

  padding: 2vh;

  p {
    color: #4b4949;
    font-weight: 600;
    font-size: 1.2vw;
  }

  span {
    font-size: 0.95vw;
    display: block;
    line-height: 1.6vw;
    margin-top: 0.7vh;

    color: #7a7a7a;

  }
}
</style>