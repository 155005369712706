anli_7<template>
    <div style="" class="anli_1">
        <div style="" class="anli_2">
            <topBar></topBar>
        </div>
        <!--  -->
        <div style="" class="anli_3">
            <div class="anli_4">{{ title }} </div>
            <div v-html="content" class="anli_5"></div>
            <div class="anli_img"> <img style="" class="anli_6"
                    src="../../assets/img/biaoti.png" alt=""></div>
        </div>
        <!--  -->
        <div class="anli_7" style="">
            <button class="left" id="anli_8" style="" @click="prevSlide"> </button>
            <div class="anli_9">


                <el-carousel ref="carousel" trigger="click" arrow="never" indicator-position="none">
                    <el-carousel-item v-for="item in anlilist" :key="item">
                        <!-- <img :src="item" alt=""> -->
                        <el-image preview-teleported style="width: 100%; height: 100%" :src="item" :zoom-rate="1.2" :max-scale="7"
                            :min-scale="0.2" :preview-src-list="anlilist" :initial-index="4"  />
                    </el-carousel-item>
                </el-carousel>

            </div>
            <button class="left" id="anli_10" style="" @click="nextSlide">
            </button>
        </div>
        <div class="anli_11" style="">
            <button class="left" @click="handerup" :disabled="shangyipian === ''">上一篇：{{ shangyipian ? shangyipian :
                '暂无' }}</button>
            <button class="left" @click="handerto" :disabled="xiayipian === ''">下一篇：{{ xiayipian ? xiayipian : '暂无'
                }}</button>
        </div>
    </div>
</template>

<script>
import topBar from '@/components/topBar.vue'

export default {
    name: 'anLi',
    components: {
        topBar
    },
    data() {
        return {
            xiayipian: '',
            shangyipian: '',
            nextid: '',//下一篇的id
            upid: '',//上一篇的id
            content: '',
            articleCat: '',
            title: '',
            idnum: '',
            slideIndex: 0,
            offset: 0,
            anlilist: []
        }
    },
    created() {
        this.idnum = this.$route.query.id
        this.articleCat = this.$route.query.articleCat
        this.getanli()
        this.getnextlist()
        this.getuplist()
    },
    methods: {
        //获取上一篇
        getuplist() {
            const text = {
                id: this.idnum,
                articleCat: this.articleCat
            }
            this.$http.get('/door/getUp/', { params: text }).then(res => {
           
                if (res.data.data) {
                    this.shangyipian = res.data.data.title
                    this.upid = res.data.data.id
           
                } else {
                    this.shangyipian = ''
                }

            });
        },
        //获取下一篇的字段
        getnextlist() {
            const text = {
                id: this.idnum,
                articleCat: this.articleCat
            }
            this.$http.get('/door/getNext/', { params: text }).then(res => {
                if (res.data.data) {
                    this.xiayipian = res.data.data.title
                    this.nextid = res.data.data.id

                } else {
                    this.xiayipian = ''
                }

            });
        },
        // 轮播事件
        prevSlide() {
            this.$refs.carousel.prev();
        },
        nextSlide() {
            this.$refs.carousel.next();
        },
        //获取列表
        getanli() {
            this.$http.get(`/door/getArticle/${this.idnum}`).then(res => {
                const anlilist = res.data.data.contentImg.split(',')
                for (let i = 0; i < anlilist.length; i++) {
                    anlilist[i] = `${this.$baseUrl}${anlilist[i]}`
                    this.anlilist.push(anlilist[i])
                }
                this.title = res.data.data.title
                this.content = res.data.data.content
            })
        },
        // 下一篇跳转路由
        handerto() {
            localStorage.setItem('text', '案例欣赏')
            window.open(this.$router.resolve({ path: 'anLi', query: { id: this.nextid, articleCat: this.articleCat } }).href, '_blank');

        },
        handerup() {
            localStorage.setItem('text', '案例欣赏')
            window.open(this.$router.resolve({ path: 'anLi', query: { id: this.upid, articleCat: this.articleCat } }).href, '_blank');
        }
    }
}
</script>

<style lang="scss" scoped>
.el-carousel__indicators {
    display: none;
}

.left {
    font-size: 0.8vw;
    border: 0;
    cursor: pointer;
    transition: transform 0.3s ease;
    background-color: #fff;
    /* 平滑放大效果 */
}

.left:hover {
    transform: scale3d(1.1, 1.1, 1);
    /* 放大至1.1倍原大小 */
}

.slideshow {
    background-color: green;

    width: 38.5vw;
    height: 45vh;
    margin-top: 2.8vh;
    display: flex;
    transition: transform 0.5s ease;
    overflow: hidden;

    /* 添加过渡效果 */
    img {
        width: 100%;
        margin-left: 4vw;
        object-fit: cover;
    }
}





.anli_1 {
    padding-left: 10vw;
    width: 75vw;

    .anli_2 {
        width: 80vw;
    }
}

.anli_3 {

    width: 60vw;

    margin-left: 10vw;
    margin-top: 6vh;
}

.anli_4 {
    text-align: center;
    height: 6vh;
    line-height: 6vh;
    color: #4a4a4a;
    font-size: 1.7vw;
    font-weight: 600;
}

.anli_5 {
    color: #757272;
    font-size: 0.9vw;
    line-height: 1.3vw;
    margin-top: 2vh;
    text-indent: 1.3vw;
}

.anli_6 {
    margin: auto;
    text-align: center;
    margin-top: 3vh;
    display: block;
    margin-bottom: 6vh;
}

.anli_7 {
    width: 70vw;
    height: 60vh;
    margin-left: 5vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5vh;
    margin-top: 3vh;
}

#anli_8 {
    background-image: url(../../assets/img/left.png);
    height: 12vh;
    width: 3vw;
    background-size: contain;
    background-repeat: no-repeat;
}

.anli_9 {
    width: 43.5vw;
    height: 63.5vh;
    background-image: url(../../assets/img/diannao.png);
    background-size: contain;
    background-repeat: no-repeat;
    padding: 1.6vw 2.75vw;
    margin-top: 18vh;

    ::v-deep .el-carousel__container {
        // width: 42.7vw;
        height: 49.5vh;
        overflow: hidden;

        // width: 34vw;
        // height: 45vh;
        // z-index: -999;
        img {
            width: 100%;
            height: 100%;
        }
    }
}

#anli_10 {
    background-image: url(../../assets/img/right.png);
    height: 12vh;
    width: 3vw;
    background-size: contain;
    background-repeat: no-repeat;
}

.anli_11 {
    width: 40vw;
    height: 5vh;
    margin-left: 20vw;
    display: flex;
    justify-content: space-between;
    margin-top: 18vh;
    margin-bottom: 8vh;
}

.anli_img {
    width: 9vw;
    height: 4vh;
    padding-left: 26vw;

    img {
        width: 100%;
        height: 100%;
    }
}
</style>