<template>
    <div style="" class="anli_1">
        <div style="" class="anli_2">
            <topBar></topBar>
        </div>
        <!--  -->
        <div style="" class="anli_3">
            <div style="  " class="anli_4">文创设计
            </div>
            <div style="" class="anli_5">
                在文创设计领域，37000cm威尼斯积累了丰富而宝贵的文创设计经验，在海报设计、画册设计、广告设计、标识设计、新媒体设计制作和动画制作等方面都有相当成熟的案例。
            </div>
            <img style="" class="anli_6" src="statics/gongsi/images/llwz.png" alt="">
        </div>
        <!--  -->
        <div class="anli_7" style="">
            <button class="left" id="anli_8" style="" @click="plusSlides(-1)">
            </button>
            <div class="anli_9" style="">

                <div class="slideshow">
                    <div class="slides">
                            <!-- <img src="uploadfile/2017/0915/20170915102712229.png" alt="">
                        <img src="uploadfile/2017/0915/20170915102823514.png" alt="">
                        <img src="uploadfile/2017/0915/20170915102903996.jpg" alt="">
                        <img src="uploadfile/2017/0915/20170915102903996.jpg" alt=""> -->
                    </div>
                </div>

            </div>
            <button class="left" id="anli_10" style="" @click="plusSlides(1)">
            </button>
        </div>
        <div class="anli_11" style="">
            <button class="left" @click="handerback">上一篇：智慧旅游服务产品</button>
            <button class="left" @click="handerto">下一篇：政企信息系统</button>
        </div>
    </div>
</template>

<script>
import topBar from '@/components/topBar.vue'

export default {
    name: 'culturalDesign',
    components: {
        topBar
    },
    data() {
        return {
            slideIndex: 0
        }
    },
    methods: {
        // 轮播事件
        plusSlides(n) {
            const slides = document.querySelector('.slides');
            const slideWidth = slides.clientWidth / 4;
            this.slideIndex = (this.slideIndex + n + 4) % 4;
            slides.style.transform = `translateX(-${this.slideIndex * slideWidth}px)`;
        },
        // 下一篇跳转路由
        handerto(){
            this.$router.push({name:'governmentInfo'})
        },
        handerback(){
            this.$router.push({name:'culturalDesign'})
        }
    }
}
</script>

<style lang="scss" scoped>
.left {
    border: 0;
    cursor: pointer;
    transition: transform 0.3s ease;
    background-color: #fff;
    /* 平滑放大效果 */
}

.left:hover {
    transform: scale(1.1);
    /* 放大至1.1倍原大小 */
}

.slideshow {

    width: 35.4vw;
    height: 42vh;
    margin-top: 2.8vh;
    position: relative;
    overflow: hidden;
}

.slides {
    display: flex;
    width: 400%;
    height: 100%;
    /* 4张图片，每张占25% */

}

.slides img {
    width: 25%;
    height: 100%;
    transition: transform 0.5s;
}

.anli_1 {
    padding-left: 10vw;

    .anli_2 {
        width: 80vw;
    }
}

.anli_3 {
    width: 60vw;
    height: 300px;
    margin-left: 10vw;
    margin-top: 6vh;
}

.anli_4 {
    text-align: center;
    height: 6vh;
    line-height: 6vh;
    color: #4a4a4a;
    font-size: 20px;
}

.anli_5 {
    color: #757272;
    font-size: 15px;
    line-height: 26px;
    margin-top: 20px;
    text-indent: 26px;
}

.anli_6 {
    margin: auto;
    text-align: center;
    margin-top: 3vh;
    display: block;
    margin-bottom: 6vh;
}

.anli_7 {
    width: 70vw;
    height: 60vh;
    margin-left: 5vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5vh;
}

#anli_8 {
    background-image: url(statics/gongsi/images/alzuo.png);
    height: 12vh;
    width: 3vw;
    background-size: contain;
    background-repeat: no-repeat;
}

.anli_9 {
    width: 40vw;
    height: 60vh;
    background-image: url(statics/gongsi/images/anlik1.png);
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
}

#anli_10 {
    background-image: url(statics/gongsi/images/alyou.png);
    height: 12vh;
    width: 3vw;
    background-size: contain;
    background-repeat: no-repeat;
}

.anli_11 {
    width: 40vw;
    height: 5vh;
    margin-left: 20vw;
    display: flex;
    justify-content: space-between;
    margin-top: 8vh;
    margin-bottom: 8vh;
}
</style>


















