import homeComponent from '@/views/index.vue'
import jieShao from '@/views/homepage/gsjieshao.vue'
import dongTai from '@/views/homepage/gsdongtai.vue'
import anLi from '@/views/homepage/gsanli.vue'
import numSchool from '@/views/homepage/numschool.vue'
import wisdomPlay from '@/views/homepage/wisdomPlay.vue'
import culturalDesign from '@/views/homepage/culturalDesign.vue'
import governmentInfo from '@/views/homepage/governmentInfo.vue'
import fangAn from '@/views/homepage/gsfangan.vue'
import zhaoPing from '@/views/homepage/gszhaoping.vue'
import gsPhone from '@/views/homepage/gsPhone.vue'
import gsyeWu from '@/views/homepage/gsyeWu.vue'
import gstuanDui from '@/views/homepage/gstuanDui.vue'
import gsziZhi from '@/views/homepage/gsziZhi.vue'
import gswenHua from '@/views/homepage/gswenHua.vue'
import dongtaiText from '@/views/homepage/dongtaiText.vue'
import gsjiShu from '@/views/homepage/gsjiShu.vue'
const routes = [
    {
        // 首页
        path: "/",
        name:'homeComponent',
        component: homeComponent
    },
    {
        // 公司简介
        path: "/jieShao",
        name:'jieShao',
        component: jieShao
    },
    {
        // 公司简介-业务
        path: "/gsyeWu",
        name:'gsyeWu',
        component: gsyeWu
    },
    {
        // 公司简介-团队
        path: "/gstuanDui",
        name:'gstuanDui',
        component: gstuanDui
    },
    {
        // 公司简介-资质
        path: "/gsziZhi",
        name:'gsziZhi',
        component: gsziZhi
    },
    {
        // 公司简介-技术
        path: "/gsjiShu",
        name:'gsjiShu',
        component: gsjiShu
    },
    {
        // 公司简介-文化
        path: "/gswenHua",
        name:'gswenHua',
        component: gswenHua
    },

    {
        // 新闻动态
        path: "/dongTai",
        name:'dongTai',
        component: dongTai
    },
    {
        // 新闻动态-详情
        path: "/dongtaiText",
        name:'dongtaiText',
        component: dongtaiText
    },
    {
        // 案例欣赏
        path: "/anLi",
        name:'anLi',
        component: anLi
    },
    {
        // 数智校园
        path: "/numSchool",
        name:'numSchool',
        component: numSchool
    },
    {
        // 智慧旅游
        path: "/wisdomPlay",
        name:'wisdomPlay',
        component: wisdomPlay
    },
    {
        // 文创设计
        path: "/culturalDesign",
        name:'culturalDesign',
        component: culturalDesign
    },
    {
        // 政企信息系统
        path: "/governmentInfo",
        name:'governmentInfo',
        component: governmentInfo
    },
    {
        // 解决方案
        path: "/fangAn",
        name:'fangAn',
        component: fangAn
    },
    {
        // 诚聘英才
        path: "/zhaoPing",
        name:'zhaoPing',
        component: zhaoPing
    },
    {
        // 联系我们
        path: "/gsPhone",
        name:'gsPhone',
        component: gsPhone
    },

 
]
export default routes;
