<template>
    <div>
        <div style="height: 7vh;margin-left: 10vw;display: flex;margin-top: 3vh;padding-top: 1vh;">
            <div class="top-left"> </div>
            <div style="margin-left: 10vw;">
                <el-tabs class="demo-tabs" @tab-change="handerjieshao" v-model="activeName">
                    <el-tab-pane label="123" name="首页">
                        <template #label>
                            <span class="two-lines">首页<br>Home</span>
                        </template>
                    </el-tab-pane>
                    <el-tab-pane label="公司简介" name="公司简介">
                        <template #label>
                            <span class="two-lines">公司简介<br>About</span>
                        </template>
                    </el-tab-pane>
                    <el-tab-pane label="新闻动态" name="新闻动态">
                        <template #label>
                            <span class="two-lines">新闻动态<br>News</span>
                        </template>
                    </el-tab-pane>
                    <el-tab-pane label="解决方案" name="解决方案">
                        <template #label>
                            <span class="two-lines">产品与行业解决方案<br>Products-Solutions</span>
                        </template>
                    </el-tab-pane>
                    <el-tab-pane label="案例欣赏" name="案例欣赏">
                        <template #label>
                            <span class="two-lines">通用产品与服务<br>Products-Service</span>
                        </template>
                    </el-tab-pane>
                    <el-tab-pane label="诚聘英才" name="诚聘英才">
                        <template #label>
                            <span class="two-lines">诚聘英才<br>Recruit</span>
                        </template>
                    </el-tab-pane>
                    <el-tab-pane label="联系我们" name="联系我们">
                        <template #label>
                            <span class="two-lines">联系我们<br>Contact</span>
                        </template>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'topList',
    data() {
        return {
            // 当前选中
            activeName: '首页'

        }
    },
    mounted: function () {
        this.activeName = localStorage.getItem('text') || '首页';
        if (this.activeName == '首页') {
            this.$router.push('/')
        }
        localStorage.removeItem('text');
    },
    created() {


    },
    methods: {
        handerjieshao(name) {
          
            
            if (name == '首页') {
                this.$router.push({ name: 'homeComponent' })
                localStorage.setItem('text', '首页')
            }
            if (name == '公司简介') {
                this.$router.push({ name: 'jieShao' })
            }
            if (name == '新闻动态') {
                this.$router.push({ name: 'dongTai' })
            }
            if (name == '案例欣赏') {
                const id = localStorage.getItem('id')
                const articleCat = localStorage.getItem('articleCat')
                this.$router.push({ name: 'anLi', query: { id: id, articleCat: articleCat } });
            }
            if (name == '解决方案') {
                const nextid=localStorage.getItem('nextid')
                this.$router.push({ name: 'fangAn', query: { id: nextid} });
                
            }
            if (name == '诚聘英才') {
                this.$router.push({ name: 'zhaoPing' })
            }
            if (name == '联系我们') {
                this.$router.push({ name: 'gsPhone' })
            }

        }
    }
}
</script>

<style lang="scss">
.top-left {
    // background-color: red;
    width: 9vw;
    height: 6vh;
    background-image: url('../assets/img/logo.png');
    background-size: contain;
    overflow: hidden;
    background-repeat: no-repeat;
}

.el-tabs {
    width: 45vw;
}

.demo-tabs>.el-tabs__content {
    color: #6b778c;
    border-bottom: none;

}

.el-tabs__item.is-top {
    text-align: center;
    font-size: 0.9vw;
    /* 设置标签字体大小为16px，根据需要调整 */
    font-weight: 400;
    margin-bottom: 1vh;
}


.el-carousel__item h3 {
    color: #475669;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
    text-align: center;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
</style>