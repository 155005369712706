<template>
    <div>
        <div class="father">
            <!-- 轮播 -->
            <topBar></topBar>
            <!--  -->
            <div class="jieshao_1">

                <div style="" class="jieshao_2">
                    <el-timeline style="max-width: 600px">
                        <el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon"
                            :type="activity.type" :color="activity.color" :size="activity.size"
                            :hollow="activity.hollow" @click="hanerto(activity.content)" style="cursor: pointer;">
                            <span style="font-size: 0.8vw;">{{ activity.content }}</span>
                        </el-timeline-item>
                    </el-timeline>
                </div>
                <!-- 右边 -->
                <div class="jieshao_3" style="">
                    <div style="width: 8.3vw;height: 7vh">
                        <img src="../../assets/img/wenhua.png" alt="">
                    </div>


                    <div style="" class="jieshao_4">
                        <div style="" class="jieshao_5">
                            <div v-html="text"></div>
                            <!-- 37000cm威尼斯（简称：37000cm威尼斯），成立于2011年3月，是一家融合互联网信息科技和创意设计为一体的文化科技型企业。公司拥有以技术专家为学科带头人和具有丰富实践经验的技术团队和设计团队，不断满足客户需求，提供特色的行业信息化及设计策划综合服务。 -->
                        </div>
                        <div class="jieshao_6" style="">
                            <img :src="textimg" alt="">
                        </div>
                    </div>

                    <!-- <div class="jieshao_7" style="">
            公司专注于智慧旅游、大数据云端、政府教育等行业的研发、实施、运行维护以及文化创意设计等业务。公司先后被认定为江苏省首批重点文化科技企业、双软企业、国家中小型科技企业等。通过近几年研发与创意的积累，公司在旅游、教育及政府、物流领域中形成了以“互联网+”为基础和导向的产品系列。
          </div> -->
                </div>
            </div>


        </div>

    </div>
</template>

<script>
import topBar from '@/components/topBar.vue'
export default {
    name: 'gswenHua',
    components: {
        topBar,
    },
    data() {
        return {
            textimg: "",
            text: "",
            activities: [

                {
                    content: '',
                    color: '#1c60c3',

                },
                {
                    content: '',
                    color: '#1c60c3',
                },
                {
                    content: '',
                    color: '#1c60c3',

                },
                {
                    content: '',
                    color: '#1c60c3',
                },
                {

                    content: '',
                    color: '#1c60c3',
                },
                {
                    content: '',
                    color: '##fff',
                    type: 'primary',
                    hollow: true,
                },
            ]
        }
    },
    created() {
        this.getlist()
    },
    methods: {
        hanerto(e) {
    

            if (e == '公司介绍') {
                this.$router.push({ name: 'jieShao' })
            }
            if (e == '公司业务') {
                this.$router.push({ name: 'gsyeWu' })
            }
            if (e == '公司团队') {
                this.$router.push({ name: 'gstuanDui' })
            }
            if (e == '公司资质') {
                this.$router.push({ name: 'gsziZhi' })
            }
            //   if(e=='公司文化'){
            //     this.$router.push({ name: 'gswenHua' })
            //   }
            if (e == '技术方向') {
                this.$router.push({ name: 'gsjiShu' })
            }
        },
        getlist() {
            this.$http.get('/door/getCategory/1').then(res => {
                this.id = res.data.data[5].id
                this.activities.forEach((activity, index) => {
                    // 查找res.data.data中对应索引的元素
                    const title = res.data.data[index].title;
                    // 替换content字段
                    activity.content = title;
                });
                this.gettext()
            })
        },
        //右边的文章
        gettext() {
            this.$http.get('/door/getArticleList', { params: { articleCat: this.id } }).then(res => {
     
                this.text = res.data.rows[0].content
                this.textimg = `${this.$baseUrl}${res.data.rows[0].contentImg}`
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.father {
    width: 80vw;
    margin-left: 10vw;
}

.jieshao_1 {
    width: 75vw;
    height: 145vh;
    margin-left: 2.5vw;
    background-color: #edf2f6;
    padding-top: 3vh;
    display: flex;
    margin-top: 0vh;
}

.jieshao_2 {
    width: 6vw;
    margin-left: 6vw;
    margin-top: 3vh;
}

.jieshao_3 {
    width: 68%;
    height: 130vh;
    background-color: #fff;
    margin-left: 0vw !important;
    padding: 2vw;
}

.jieshao_4 {
    width: 100%;
    margin-top: 3vh;
    display: flex
}

.jieshao_5 {
    width: 55%;
    font-size: 16px;
    color: #2d2d2d;
    text-indent: 1.3vw;
    line-height: 4vh;
    float: left;
    margin-top: 2vh;
}

.jieshao_6 {
    width: 50%;
    height: 280px;
    margin-left: 2vw;

    img {
        width: 100%;
        height: 100%;
    }
}

.jieshao_7 {
    margin-top: 2vh;
    width: 90%;
    font-size: 16px;
    color: #2d2d2d;
    text-indent: 1.3vw;
    line-height: 4vh;
    float: left;
}
</style>