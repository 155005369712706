<template>
    <div class="dongtai_1">
        <div class="dongtai_2">
            <topBar></topBar>
        </div>
        <div style="" class="dongtai_3">
            <div>
                <div style="" class="dongtai_4">新闻动态
                </div>
                <div style="" class="dongtai_5">NEWS</div>
            </div>
            <!--  -->
            <div style="" class="dongtai_6">
                <div style="" v-for="item in textlist" :key="item.id" class="dongtai_7">
                    <div class="dongtai_8">
                        <div style="" class="dongtai_9">{{ item. day}}</div>
                        <div style="" class="dongtai_10">{{item.year}}-{{item.month}}</div>
                    </div>
                    <div style="" class="dongtai_11">
                        <span style="" class="dongtai_12" @click="handertext(item.id)">{{item.title}}</span>
                    </div>
                </div>
                <!-- 分页 -->
                 <div style="width: 80vw;display: flex;justify-content: center">
                    <div style="" class="dongtai_13">
                    <el-pagination background layout="total, prev, pager, next, jumper"  :total="total" :page-size="pageSize"
                        @current-change="handleCurrentChange" :current-page="currentPage"  />
                </div>
                 </div>
              

            </div>

        </div>
    </div>
</template>

<script>
import topBar from '@/components/topBar.vue'
export default {
    components: {
        topBar
    },
    name: 'dongTai',
    data() {
        return {
            textlist: [],
            total: '',
            pageSize: 5,  // 默认每页显示10条
            currentPage: 1,  // 初始页码
        }
    },
    created() {
        this.gettext()
    },
    methods: {
        // 获取刘表
        gettext() {
            this.$http.get('/door/getArticleList', { params: { articleCat: 7, pageSize: 5, pageNum: 1 } }).then(res => {
                this.total = res.data.total
                this.textlist = res.data.rows.map(item => {
                    // 截取年、月、日
                    const createTime = item.createTime;
                    const year = createTime.slice(0, 4);
                    const month = createTime.slice(5, 7);
                    const day = createTime.slice(8, 10);

                    // 将截取的年、月、日添加到item对象中
                    return { ...item, year, month, day };
                });
        
            })
        },
        //分页
        handleCurrentChange(val) {
            this.currentPage = val
            this.$http.get('/door/getArticleList', { params: { articleCat: 7, pageSize: 5, pageNum: val } }).then(res => {
                this.total = res.data.total
                this.textlist = res.data.rows.map(item => {
                    // 截取年、月、日
                    const createTime = item.createTime;
                    const year = createTime.slice(0, 4);
                    const month = createTime.slice(5, 7);
                    const day = createTime.slice(8, 10);

                    // 将截取的年、月、日添加到item对象中
                    return { ...item, year, month, day };
                });
            })
        },
        // 点击跳转详情
        handertext(id){
            this.$router.push(`/dongtaiText/?id=${id}`)
        }
    }
}
</script>

<style lang="scss" scoped>
.dongtai_1 {
    padding-left: 10vw;

    .dongtai_2 {
        width: 80vw;
    }
}

.dongtai_3 {
    width: 75vw;
    margin-left: 2.5vw;
    background-color: #edf2f6;
    padding-top: 4vh;
    
}

.dongtai_4 {
    font-size: 2.1vw;
    color: #3f4040;
    text-align: center;
    display: block;
    margin-top: 6px;
}

.dongtai_5 {
    text-align: center;
    font-size: 0.9vw;
    color: #606263;
}

.dongtai_6 {
    width: 100%;
    background-color: #edf2f6;
}

.dongtai_7 {
    margin-top: 6vh;
    display: flex;
}

.dongtai_8 {
  height: 13vh;
    margin-left: 10vw;
    width: 7vw;
    overflow: hidden;
    background: url(../../assets/img/rili.png) no-repeat;
    float: left;
}

.dongtai_9 {
   
    width: 7vw;
    height: 9vh;
    line-height: 9vh;
    text-align: center;
    font-size: 3vw;
    color: #FFFFFF;
}

.dongtai_10 {

    width: 7vw;
    height: 4vh;
    display: block;
    text-align: center;
    line-height: 4vh;
    font-size: 1vw;
    color: #FFFFFF;
  
} 

.dongtai_11 {
    margin-left: 2vw;
    width: 45vw;
    height: 17vh;
    overflow: hidden;
    background: #FFFFFF;
    border-radius: 3px;
    padding: 1vw;
}

.dongtai_12 {
    font-size: 19px;
    color: #373636;
    &:hover{
        color: skyblue;
        cursor: pointer;
    }
}

.dongtai_13 {
    height: 8vh;
    width: 50vw;
    justify-content: center;
    display: flex;
}
</style>