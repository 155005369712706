import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import router from "./router/index"
import axios from 'axios'
import BaiduMap from 'vue-baidu-map-3x'
// import Quill from "quill";
import '.store/quill@1.3.7/node_modules/quill/dist/quill.bubble.css'  
import '.store/quill@1.3.7/node_modules/quill/dist/quill.snow.css'
import '.store/quill@1.3.7/node_modules/quill/dist/quill.bubble.css'
import "animate.css"
// const baseUrl = 'http://192.168.3.114:8090'
const baseUrl = 'http://admin.jsxinyuansu.com/prod-api'
axios.defaults.baseURL = baseUrl
// router.beforeEach((to, from, next) => {
//     window.scrollTo(0, 0);
//     next();
//   });
var app = createApp(App)
app.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'ZIr5htXn8Sh4Ks9wbIMDhFAZKHZUCldR',
  // v:'2.0',  // 默认使用3.0
  // type: 'WebGL' // ||API 默认API  (使用此模式 BMap=BMapGL)
});
app.config.globalProperties.$http=axios
app.config.globalProperties.$baseUrl=baseUrl
app.use(ElementPlus,{
  locale: zhCn,
})
app.use('Quill')
app.use(router)
app.mount('#app')
