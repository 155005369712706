<template>
    <div style="position: relative;">

        <div class="topbanr">

            <div class="vx_img" @mouseover="showBox" @mouseleave="hideBox">
                <img src="../assets/img/weixing.png">
            </div>


            <div class="wxqipao" v-if="show">
                <img src="../assets/img/eriweima.png" alt="">
            </div>


            <div class="phone_img" >
                <img src="../assets/img/phone.png" alt="">
                <p>0518/81883980</p>
            </div>

            <div  @click="totop">
                <img src="../assets/img/dingbu.png" alt="" style="">

            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'phoneBar',
    data() {
        return {
            show:false
        }
    },
    methods: {
        totop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // 平滑滚动
            });
        },
        showBox(){
            this.show=true
        },
        hideBox(){
            this.show=false
        }
    }

}
</script>

<style lang="scss">
.topbanr {

    position: fixed;
    /* 设置为固定定位 */
    right: -7.2vw;
    /* 距离右边框20px */
    bottom: 15vh;
    /* 距离顶部20px，可以根据需要调整 */

}

.wxqipao {
    width: 5vw;
    height: 10vh;
    background-color: red;
    position: absolute;
    top: 0;
    left: -7.5vw;
    z-index: 999
}

.phone_img {
    align-items: center;
    display: flex;
    width: 10vw;
    background-color: #333333;
    transition: transform 0.8s;
    border-bottom: 1px solid #fff;

  

    &:hover {
        transform: translateX(-7vw)
    }

    p {
        width: 100%;
        color: #fff;
        font-size: 0.8vw;
        line-height: 5vh;
        text-align: center;
    }
}

.vx_img {
    align-items: center;
    display: flex;
    width: 10vw;
    background-color: #333333;
    transition: transform 0.8s;
    border-bottom: 1px solid #fff;

}

.transition-box {
    background-color: black;
    width: 10vw;
    height: 4vh;
    text-align: center;

    p {
        color: #fff;
        font-size: 1.2vw;
        line-height: 5vh;
        text-align: center;
    }
}
</style>
